<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="skin-settings">
      <div class="title">
        <i class="fa fa-cogs fa-spin"></i
        ><span style="margin-left: 4%">Configuration</span><br />
      </div>
      <div class="setings-item">
        <span> Collapse menu </span>

        <div class="switch">
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="collapsemenu"
              class="onoffswitch-checkbox"
              id="collapsemenu"
            />
            <label class="onoffswitch-label" for="collapsemenu">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="setings-item">
        <span> Fixed sidebar </span>

        <div class="switch">
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="fixedsidebar"
              class="onoffswitch-checkbox"
              id="fixedsidebar"
            />
            <label class="onoffswitch-label" for="fixedsidebar">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="setings-item">
        <span> Top navbar </span>

        <div class="switch">
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="fixednavbar"
              class="onoffswitch-checkbox"
              id="fixednavbar"
            />
            <label class="onoffswitch-label" for="fixednavbar">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="setings-item">
        <span>
          Top navbar v.2
          <br />
          <small>*Primary layout</small>
        </span>

        <div class="switch">
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="fixednavbar2"
              class="onoffswitch-checkbox"
              id="fixednavbar2"
            />
            <label class="onoffswitch-label" for="fixednavbar2">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="setings-item">
        <span> Boxed layout </span>

        <div class="switch">
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="boxedlayout"
              class="onoffswitch-checkbox"
              id="boxedlayout"
            />
            <label class="onoffswitch-label" for="boxedlayout">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="setings-item">
        <span> Fixed footer </span>

        <div class="switch">
          <div class="onoffswitch">
            <input
              type="checkbox"
              name="fixedfooter"
              class="onoffswitch-checkbox"
              id="fixedfooter"
            />
            <label class="onoffswitch-label" for="fixedfooter">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="title">Skins</div>
      <div class="setings-item default-skin">
        <span class="skin-name">
          <a href="#" class="s-skin-0"> Default </a>
        </span>
      </div>
      <div class="setings-item blue-skin">
        <span class="skin-name">
          <a href="#" class="s-skin-1"> Blue light </a>
        </span>
      </div>
      <div class="setings-item ultra-skin">
        <span class="skin-name">
          <a href="#" class="s-skin-2"> Material Design </a>
        </span>
      </div>
      <div class="setings-item light-skin">
        <span class="skin-name">
          <a href="#" class="s-skin-3"> Light Skin </a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted: function () {
    var self = this;
    // Enable/disable fixed top navbar
    $("#fixednavbar").click(function () {
      $('.welcome-message').show();
        self.$forceUpdate();
      if ($("#fixednavbar").is(":checked")) {
        $(".navbar-static-top")
          .removeClass("navbar-static-top")
          .addClass("navbar-fixed-top");
        $("body").removeClass("boxed-layout");
        $("body").addClass("fixed-nav");
        $("#boxedlayout").prop("checked", false);

        if (localStorageSupport) {
          localStorage.setItem("boxedlayout", "off");
        }

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar", "on");
        }
      } else {
        $(".navbar-fixed-top")
          .removeClass("navbar-fixed-top")
          .addClass("navbar-static-top");
        $("body").removeClass("fixed-nav");
        $("body").removeClass("fixed-nav-basic");
        $("#fixednavbar2").prop("checked", false);

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar", "off");
        }

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar2", "off");
        }
      }
    });

    // Enable/disable fixed top navbar
    $("#fixednavbar2").click(function () {
      $('.welcome-message').show();
        self.$forceUpdate();
      if ($("#fixednavbar2").is(":checked")) {
        $(".navbar-static-top")
          .removeClass("navbar-static-top")
          .addClass("navbar-fixed-top");
        $("body").removeClass("boxed-layout");
        $("body").addClass("fixed-nav").addClass("fixed-nav-basic");
        $("#boxedlayout").prop("checked", false);

        if (localStorageSupport) {
          localStorage.setItem("boxedlayout", "off");
        }

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar2", "on");
        }
      } else {
        $(".navbar-fixed-top")
          .removeClass("navbar-fixed-top")
          .addClass("navbar-static-top");
        $("body").removeClass("fixed-nav").removeClass("fixed-nav-basic");
        $("#fixednavbar").prop("checked", false);

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar2", "off");
        }
        if (localStorageSupport) {
          localStorage.setItem("fixednavbar", "off");
        }
      }
    });

    // Enable/disable fixed sidebar
    $("#fixedsidebar").click(function () {
      $('.welcome-message').show();
        self.$forceUpdate();
      if ($("#fixedsidebar").is(":checked")) {
        $("body").addClass("fixed-sidebar");
        $(".sidebar-collapse").slimScroll({
          height: "100%",
          railOpacity: 0.9,
        });

        if (localStorageSupport) {
          localStorage.setItem("fixedsidebar", "on");
        }
      } else {
        $(".sidebar-collapse").slimScroll({ destroy: true });
        $(".sidebar-collapse").attr("style", "");
        $("body").removeClass("fixed-sidebar");

        if (localStorageSupport) {
          localStorage.setItem("fixedsidebar", "off");
        }
      }
    });

    // Enable/disable collapse menu
    $("#collapsemenu").click(function () {
      $('.welcome-message').show();
        self.$forceUpdate();
      if ($("#collapsemenu").is(":checked")) {
        $("body").addClass("mini-navbar");
        SmoothlyMenu();

        if (localStorageSupport) {
          localStorage.setItem("collapse_menu", "on");
        }
      } else {
        $("body").removeClass("mini-navbar");
        SmoothlyMenu();

        if (localStorageSupport) {
          localStorage.setItem("collapse_menu", "off");
        }
      }
    });

    // Enable/disable boxed layout
    $("#boxedlayout").click(function () {
      if ($("#boxedlayout").is(":checked")) {
         $('.welcome-message').hide();
        self.$forceUpdate();
        $("body").addClass("boxed-layout");
        $("#fixednavbar").prop("checked", false);
        $("#fixednavbar2").prop("checked", false);
        $(".navbar-fixed-top")
          .removeClass("navbar-fixed-top")
          .addClass("navbar-static-top");
        $("body").removeClass("fixed-nav");
        $("body").removeClass("fixed-nav-basic");
        $(".footer").removeClass("fixed");
        $("#fixedfooter").prop("checked", false);

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar", "off");
        }

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar2", "off");
        }

        if (localStorageSupport) {
          localStorage.setItem("fixedfooter", "off");
        }

        if (localStorageSupport) {
          localStorage.setItem("boxedlayout", "on");
        }
      } else {
         $('.welcome-message').show();
        self.$forceUpdate();
        $("body").removeClass("boxed-layout");

        if (localStorageSupport) {
          localStorage.setItem("boxedlayout", "off");
        }
        self.$forceUpdate();
      }
    });

    // Enable/disable fixed footer
    $("#fixedfooter").click(function () {
      $('.welcome-message').show();
        self.$forceUpdate();
      if ($("#fixedfooter").is(":checked")) {
        $("#boxedlayout").prop("checked", false);
        $("body").removeClass("boxed-layout");
        $(".footer").addClass("fixed");

        if (localStorageSupport) {
          localStorage.setItem("boxedlayout", "off");
        }

        if (localStorageSupport) {
          localStorage.setItem("fixedfooter", "on");
        }
      } else {
        $(".footer").removeClass("fixed");

        if (localStorageSupport) {
          localStorage.setItem("fixedfooter", "off");
        }
      }
    });

    // SKIN Select
    $(".spin-icon").click(function () {
      $(".theme-config-box").toggleClass("show");
    });

    // Default skin
    $(".s-skin-0").click(function () {
      $("body").removeClass("skin-1");
      $("body").removeClass("skin-2");
      $("body").removeClass("md-skin");
      $("body").removeClass("light-skin");
    });

    // Blue skin
    $(".s-skin-1").click(function () {
      $("body").removeClass("skin-1");
      $("body").addClass("skin-2");
      $("body").removeClass("md-skin");
      $("body").removeClass("light-skin");
    });

    // Inspinia ultra skin
    $(".s-skin-2").click(function () {
      $("body").removeClass("skin-1");
      $("body").removeClass("skin-2");
      $("body").addClass("md-skin");
      $("body").removeClass("light-skin");
    });

    // Yellow skin
    $(".s-skin-3").click(function () {
      $("body").removeClass("skin-1");
      $("body").removeClass("skin-2");
      $("body").removeClass("md-skin");
      $("body").addClass("light-skin");
    });

    function localStorageSupport() {
      return "localStorage" in window && window["localStorage"] !== null;
    }

    if (localStorageSupport) {
      var collapse = localStorage.getItem("collapse_menu");
      var fixedsidebar = localStorage.getItem("fixedsidebar");
      var fixednavbar = localStorage.getItem("fixednavbar");
      var fixednavbar2 = localStorage.getItem("fixednavbar2");
      var boxedlayout = localStorage.getItem("boxedlayout");
      var fixedfooter = localStorage.getItem("fixedfooter");

      if (collapse == "on") {
        $('.welcome-message').show();
        $("#collapsemenu").prop("checked", "checked");
        self.$forceUpdate();
      }
      if (fixedsidebar == "on") {
        $('.welcome-message').show();
        $("#fixedsidebar").prop("checked", "checked");
        self.$forceUpdate();
      }
      if (fixednavbar == "on") {
        $('.welcome-message').show();
        $("#fixednavbar").prop("checked", "checked");
        self.$forceUpdate();
      }
      if (fixednavbar2 == "on") {
        $('.welcome-message').show();
        $("#fixednavbar2").prop("checked", "checked");
        self.$forceUpdate();
      }
      if (boxedlayout == "on") {
        $("#boxedlayout").prop("checked", "checked");
      }
      if (fixedfooter == "on") {
        $('.welcome-message').show();
        $("#fixedfooter").prop("checked", "checked");
        self.$forceUpdate();
      }
    }
  },
};
</script>
<style scoped>
.light-skin {
  background-color: #6c757d5c;
  text-align: center;
}
</style>
